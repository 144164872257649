@tailwind base;
@tailwind components;
@tailwind utilities;


.sticky {
    position: -webkit-sticky;
    position: sticky;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    transition-delay: 50ms;
    top: 0;
    background-color: #E3E5E6;
    border: none;
}

.cursive {
    font-family: 'Lucida Handwriting', cursive;
}

.hakkinda-yazi {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 60rem;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin: 20px;
  }
  /* Başlık stilleri */
  .hakkinda-yazi h1 {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 24px;
    color: #333;
  }
  /* Paragraf stilleri */
  .hakkinda-yazi p {
    font-size: 16px;
    color: #666;
  }

  .hakkinda-yazi h2 {
    
    margin-bottom: 5px;
    font-size: 18px;
    color: #333;
  
  }